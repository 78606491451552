<template>
	<main>
		<h1>Settings</h1>

		<form @submit.prevent="updateSettings">
			<div class="columns">
				<div class="form-group">
					<label for="name">Name</label>
					<input id="name" v-model="tenant.name" type="text" autocomplete="off" />
				</div>

				<div class="form-group">
					<label for="email">Support email address</label>
					<input id="email" v-model="tenant.email" type="email" autocomplete="off" />
				</div>
			</div>

			<div class="columns">
				<div class="form-group">
					<label for="domain">Elemes Domain</label>
					<p data-append-field=".elemes.com">
						<input id="domain" v-model="tenant.slug" type="text" autocomplete="off" />
					</p>
				</div>

				<div class="form-group">
					<label for="custom-domain">Custom domain (must have a CNAME record that points to {{ tenant.slug }}.elemes.com)</label>
					<input id="custom-domain" v-model="tenant.domain" type="text" autocomplete="off" />
				</div>
			</div>

			<div class="columns">
				<div class="form-group">
					<label for="custom-s3-endpoint">Custom S3 endpoint</label>
					<input id="custom-s3-endpoint" v-model="tenant.s3endpoint" type="text" placeholder="Leave blank to use default" autocomplete="off" />
				</div>

				<div class="form-group">
					<label for="custom-s3-key">Custom S3 key</label>
					<input id="custom-s3-key" v-model="tenant.s3key" type="text" placeholder="Leave blank to use default" autocomplete="off" />
				</div>

				<div class="form-group">
					<label for="custom-s3-secret">Custom S3 secret</label>
					<input id="custom-s3-secret" v-model="tenant.s3secret" type="password" placeholder="Leave blank to use default" autocomplete="off" />
				</div>
			</div>

			<div class="form-group">
				<BoolInput :checked="tenant.enableRegistration" label="Enable registration" @input="checked => tenant.enableRegistration = checked" />
			</div>

			<div class="form-group">
				<label for="dropzone">Logotype</label>
				<div class="logotype-wrapper">
					<div v-if="fileUrl" class="current-file">
						<img :src="fileUrl" />
					</div>
					<DropZone id="dropzone" :is-saving="isSaving" :accept-types="acceptTypes" @upload="filesChange" />
				</div>
			</div>

			<BaseButton type="submit" :disabled="isSaving">
				Update
			</BaseButton>
		</form>
	</main>
</template>

<script>
	import {roleGuard} from '../../inc/auth';
	import DropZone from '../../components/DropZone.vue';
	import Bus from '../../inc/bus';
	import ObjectStore from '../../inc/objectStore';
	import Store from '../../inc/store';
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton.vue';
	import BoolInput from '../../components/BoolInput.vue';

	const STATUS_FAILED = 3,
		STATUS_INITIAL = 0,
		STATUS_SAVING = 1,
		STATUS_SUCCESS = 2;

	export default {
		...roleGuard('admin'),
		components: {
			DropZone,
			BaseButton,
			BoolInput
		},
		data() {
			return {
				currentStatus: null,
				uploadError: null,
				fileUrl: '',
				acceptTypes: [
					{
						label: 'Image',
						code: 'image',
						mimeTypes: [
							'image/jpeg',
							'image/png',
							'image/gif',
							'image/bmp',
							'image/svg'
						]
					}
				]
			};
		},
		computed: {
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
			tenant() {
				return Store.tenant;
			}
		},
		mounted() {
			this.reset();
		},
		created() {
			if(this.tenant.logotype) {
				ObjectStore.getFileUrl(this.tenant.logotype).then(url => {
					this.fileUrl = url;
				});
			}
		},
		methods: {
			updateSettings() {
				Backend.put('tenants', this.tenant).then(() => Bus.emit('success', 'Settings updated!')).catch(err => Bus.emit('error', err.message));
			},
			filesChange(fileList) {
				if(fileList.length !== 1) {
					Bus.emit('error', 'Invalid number of files.');

					return;
				}

				this.currentStatus = STATUS_SAVING;
				const allowedFileTypes = [];

				for(const type of this.acceptTypes) {
					allowedFileTypes.push(...type.mimeTypes);
				}

				if(!allowedFileTypes.includes(fileList[0].type)) {
					Bus.emit('error', `File type not allowed: ${fileList[0].type}. Skipping...`);

					return;
				}

				this.upload(fileList[0]).then(() => {
					this.currentStatus = STATUS_SUCCESS;
					ObjectStore.getFileUrl(this.tenant.logotype).then(url => {
						this.fileUrl = url;
					});
				}).catch(err => {
					this.uploadError = err.response;
					this.currentStatus = STATUS_FAILED;
				});
			},
			reset() {
				this.currentStatus = STATUS_INITIAL;
				this.uploadError = null;
			},
			getAllowedFileTypes() {
				const mimeTypes = [];

				for(const type of this.acceptTypes) {
					mimeTypes.push(...type.mimeTypes);
				}

				return mimeTypes;
			},
			upload(file) {
				return ObjectStore.upload(file).then(uploaded => this.$set(this.tenant, 'logotype', uploaded));
			}
		}
	};
</script>

<style lang="scss" scoped>
	label:not(.bool-input) {
		display: block;
		margin-bottom: 10px;
	}

	.logotype-wrapper {
		display: flex;
		flex-flow: row wrap;
		margin-bottom: $default_padding;

		.dropzone {
			flex: 1;
		}

		.current-file {
			margin-right: $default_padding;
			max-height: 200px;
			align-self: center;
		}
	}

	.form-group {
		margin-bottom: $default_padding;
	}

	[data-append-field] {
		display: flex;
		background: $color__white;
		box-shadow: $box_shadow__input;
		border-radius: $border_radius;
		text-align: right;

		input {
			margin-bottom: 0;
			padding-right: 0.25rem;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			text-align: inherit;

			&:not(:focus) {
				box-shadow: none;
			}
		}

		&::after {
			content: attr(data-append-field);
			font-size: 1.25rem;
			line-height: 1em;
			padding: 0.5rem 1rem 0.5rem 0.25rem;
			opacity: 0.5;
		}
	}

	.columns {
		display: flex;
		gap: 20px;

		& > * {
			flex: 1;
		}
	}
</style>